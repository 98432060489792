/* NOTE: Fix nav ul .nav-cta a */


.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    Padding: 0;
}

body {
    font-family: "Poppins", sans-serif;
    background: #181818;
}

li,
a,
button {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #ecf0f1;
    text-decoration: none;
}

/* Homepage Hero Section */
header {
    height: 100px;
    color: whitesmoke;
}

.logo h1 a {
    font-size: 37px;
    color: white;
    margin: 0;
    font-weight: 700;
}

nav {
    padding: 2% 10%;
    display: flex;
    justify-content: space-between;
}

    nav box-icon {
        display: none;
    }

    nav ul {
        list-style-type: none;
    }

        nav ul li {
            display: inline-block;
            margin-left: 2.5rem;
        }

        nav ul .nav-cta a {
            border: 2px solid #EE811A;
            background: #EE811A;
            color: #000000;
            padding: 6px 10px;
            border-radius: 10px;
            font-size: 30px;
            margin: -75px;
        }

        nav ul .nav-cta a:hover {
            color: #fff;
            transition: .5s ease-in-out;
        }

        nav ul .nav-signout a {
            border: 2px solid #353535;
            background-color: #353535;
            padding: 6px 10px;
        }

        nav ul li a:hover {
            color: #2F8AE4;
            transition: .5s ease-in-out;
        }

/* Homepage Hero Section */
section {
    display: flex;
    height: calc(100vh - 100px);
    align-items: center;
    justify-content: center;
    padding-left: 20%;
    padding-right: 20%;
}

.hero {
    /* width: 75vw; */
    color: white;
    height: 100%;
}

    .hero h1 {
        font-weight: 700;
        font-size: 64px;
        line-height: 112.5%;
        margin-bottom: 2rem;
    }

    .hero p {
        font-weight: 400;
        font-size: 18px;
    }

    .hero .header-cta {
        border: 2px solid #EE811A;
        background: #EE811A;
        padding: 8px 100px;
        margin: 10px;

    }

.header-cta a {
    font-size: 18px;
}

/* Media Query */

@media only screen and (max-width: 768px) {

    /*Nav Stlying */
    nav {
        flex-direction: column;
        position: relative;
    }

    nav box-icon {
        display: block;
        position: absolute;
        top: 18px;
        right: 32px;
    }

    .logo h1 a {
        font-size: 26px;
    }

    nav ul {
        display: none;
    }

    .showmenu {
        display: block;
    }

    nav ul li {
        display: block;
        padding: 3% 1px;
        margin-left: 0px;
    }

    nav ul .nav-cta {
        border: 0;
        padding: 3% 1px;
        color: #2F8AE4;
    }

    /* Hero Styling */

    .header {
        padding: 0 10%;
    }

    .hero h1 {
        font-size: 50px;
    }

    .hero p {
        font-size: 16px;
    }

    .hero .header-cta {
        border: 2px solid #2F8AE4;
        color:#000000;
        padding: 10px 17px;
        font-size: 16px;
    }
}


/* My dApps menu */

.dropdown {
    margin: 15% 0%;
}

.dropdown-menu {
    border: 5px solid #EE811A;
    background: #EE811A;
    color: #000000;
    border-radius: 10px;
    padding: 6px 10px;
    font-size: 30px
}

.dropdown:hover .dropdown-content {
    display:block;
    padding: 1%;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #222222;
    text-align: left;
    border-radius: 10%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    width: 30%;
  }

.dropdown-content a {
    font-size: 30px;
    line-height: 250%;
}