.DescriptionBox {
    width: 500px;
    height: 400px;
    font-size: 25px;
    color: #333;
    background-color: #e8e8e8;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #ccc;
}

.SetGrantAmount {
    width: 200px;
    height: 50px;
    font-size: 25px;
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #ccc;
}

.SetRecipient {
    width: 650px;
    height: 50px;
    background-color: #e8e8e8;
    font-size: 25px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #ccc;
}

.ButtonStyle a {
    text-align: center;
    background-color: #ee811a;
    color: #000000;
    width: 20%;
    padding: 10px;
    font-size: 30px;
    text-align: center;
    border-radius: 10px;
    margin: 1.5px;
}