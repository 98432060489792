main {
    display: grid;
    grid-template-columns: 1fr repeat(12, minmax(auto, 60px)) 1fr;
    grid-gap: 40px;
    padding: 60px 0;
}

.text--medium {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #ecf0f1;
}

.text__price {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    line-height: 20px;
    margin-top: auto;
    font-weight: 400;
    color: #ecf0f1;
}

.cards {
    grid-column: 2 / span 12;
    display: grid;
    grid-template-columns: repeat(12, minmax(auto, 60px));
    grid-gap: 40px;
}

.card {
    grid-column-end: span 4;
    display: flex;
    flex-direction: column;
    background-color: #39393b;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    min-width: 300px;
}

    .card:hover {
        transform: translateY(-7px);
        box-shadow: none;
    }

    .card img:hover {
        box-shadow: none;
    }

.card__image-container {
    width: 100%;
    padding-top: 80%;
    overflow: hidden;
    position: relative;
}

    .card__image-container img {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }



.card__content {
    padding: 20px;
}

.card__title {
    margin-bottom: 20px;
    font-size: 24px;
}

.card__info {
    display: flex;
    align-self: end;
    align-items: center;
}

.card__price {
    margin-left: auto;
    padding: 5px 20px;
    background-color: #303032;
    /* border-radius: 20px; */
    text-align: center;
}

.price__top {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.price__bottom {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

@media only screen and (max-width: 1000px) {
    .card {
        grid-column-end: span 6;
    }
}

@media only screen and (max-width: 700px) {
    main {
        grid-gap: 20px;
    }

    .card {
        grid-column-end: span 12;
    }
}

@media only screen and (max-width: 500px) {
    main {
        grid-template-columns: 10px repeat(6, 1fr) 10px;
        grid-gap: 10px;
    }

    .cards {
        grid-column: 2 / span 6;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 20px;
    }

    .card {
        grid-column-end: span 6;
    }
}


.buyIcon {
    width: 84px;
    padding: 10px;
}

    .buyIcon:hover {
        opacity: .5;
        box-shadow: none;
    }